<template>
  <title-wrapper :title="$t('components.wizard.moduleNumber.title')">
    <div class="white pa-6">
      <v-container class="pa-0 mb-4">
        <v-row>
          <v-col class="font-weight-bold">
            {{ $t('components.wizard.moduleNumber.modulesAmount') }}
            {{ !isMixedPanelType ? `- ${direction}` : '' }}</v-col
          >
          <v-col class="font-weight-bold"
            >{{ $t('components.wizard.moduleNumber.summary') }}
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-form v-model="isValid" v-if="rows" ref="moduleNumberForm">
          <v-row>
            <v-col class="mb-0 pb-0">
              <v-row v-if="panelOrientation !== panelOrientations.POZIOM">
                <v-col class="mb-0 pb-0">
                  <div v-if="isMixedPanelType">
                    <h1 class="text mb-1">
                      {{ $t('components.wizard.moduleNumber.amountUpright') }}
                    </h1>
                    <v-divider class="mb-1"></v-divider>
                  </div>
                  <div v-for="row in verticalRows" :key="row.index">
                    <h2 class="text mb-1 mt-2">
                      {{ $t('components.wizard.moduleNumber.row') }} {{ row.index }}
                    </h2>
                    <v-text-field
                      :name="`moduleNumber-${row.index}`"
                      :label="$t('components.wizard.moduleNumber.amountUpright')"
                      type="number"
                      min="0"
                      hide-details="auto"
                      :rules="validators.modulesNumberRules"
                      v-model="row.modulesNumber"
                      required
                      outlined
                      solo
                      elavation="0"
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="panelOrientation !== panelOrientations.PION">
                <v-col class="mb-0 pb-0">
                  <div v-if="isMixedPanelType">
                    <h1 class="text mb-1">
                      {{ $t('components.wizard.moduleNumber.amountHorizontal') }}
                    </h1>
                    <v-divider class="mb-1"></v-divider>
                  </div>
                  <div v-for="row in horizontalRows" :key="row.index">
                    <h2 class="text mb-2 mt-2">
                      {{ $t('components.wizard.moduleNumber.row') }} {{ row.index }}
                    </h2>
                    <v-text-field
                      :name="`moduleNumber-${row.index}`"
                      :label="$t('components.wizard.moduleNumber.amountHorizontal')"
                      type="number"
                      min="0"
                      hide-details="auto"
                      :rules="validators.modulesNumberRules"
                      v-model="row.modulesNumber"
                      required
                      outlined
                      solo
                      elavation="0"
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <div v-if="panelOrientation !== panelOrientations.POZIOM">
                <h2 class="text mb-2">{{ $t('components.wizard.moduleNumber.rowsUpright') }}</h2>
                <v-divider></v-divider>
                <div v-for="row in verticalRows" :key="`summary-${row.index}`">
                  <div class="d-flex align-center justify-space-between mt-4">
                    <div>{{ $t('components.wizard.moduleNumber.row') }} {{ row.index }}</div>
                    <div>{{ row.modulesNumber >= 0 ? row.modulesNumber : 0 }}</div>
                  </div>
                  <v-divider class="mt-4"></v-divider>
                </div>
              </div>
              <div v-if="panelOrientation !== panelOrientations.PION">
                <h2 class="text mb-2 mt-2">
                  {{ $t('components.wizard.moduleNumber.rowsHorizontal') }}
                </h2>
                <div v-for="row in horizontalRows" :key="`summary-${row.index}`">
                  <div class="d-flex align-center justify-space-between mt-4">
                    <div>{{ $t('components.wizard.moduleNumber.row') }} {{ row.index }}</div>
                    <div>{{ row.modulesNumber >= 0 ? row.modulesNumber : 0 }}</div>
                  </div>
                  <v-divider class="mt-4"></v-divider>
                </div>
              </div>
              <div>
                <div class="d-flex align-center justify-space-between mt-4">
                  <div>{{ $t('helpers.sum') }} ({{ direction }})</div>
                  <div>{{ sumOfAllRows }}</div>
                </div>
                <v-divider class="mt-4"></v-divider>
              </div>
              <div>
                <div class="d-flex align-center justify-space-between mt-4 font-weight-bold">
                  <div>{{ $t('components.wizard.moduleNumber.amountOfModules') }}</div>
                  <div>{{ sumOfAllRows }}</div>
                </div>
              </div>
              <div>
                <div class="d-flex align-center justify-space-between mt-4 font-weight-bold">
                  <div>{{ $t('components.wizard.moduleNumber.amountOfModulesSelected') }}</div>
                  <div>{{ sumOfModules() }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import { validators } from '../../../validators/Validators';
import {
  verticalRowNumberComputed,
  horizontalRowNumberComputed,
  verticalModulesNumbersComputed,
  horizontalModulesNumbersComputed,
  panelOrientationComputed,
  wizardSubstepComputed,
  constructionCalculatorAttributesComputed,
  wizardTypeComputed,
} from '../../../store/helper.js';
import { PANEL_ORIENTATION } from '../../../static/fotoConstants.js';
import { sum } from 'lodash';
import VueI18n from '../../../plugins/i18n';
import { wizardTypes } from '../../../static/wizardTypes';

export default {
  name: 'ModuleNumber',
  components: { TitleWrapper },
  data() {
    return {
      isValid: false,
      modulesNumber: 0,
      storeAction: 'setModulesNumbers',
      storeActionVerticalModules: 'setVerticalModulesNumbers',
      storeActionHorizontalModules: 'setHorizontalModulesNumbers',
      panelOrientations: PANEL_ORIENTATION,
      rows: [],
      verticalRows: [],
      horizontalRows: [],
      type: {
        name: 'ModuleNumbers',
        isRoofConstruction: true,
      },
    };
  },
  computed: {
    ...verticalRowNumberComputed,
    ...horizontalRowNumberComputed,
    ...panelOrientationComputed,
    ...wizardSubstepComputed,
    ...verticalModulesNumbersComputed,
    ...horizontalModulesNumbersComputed,
    ...constructionCalculatorAttributesComputed,
    ...wizardTypeComputed,
    direction() {
      return this.$i18n.t(`statics.panelOrientations.${this.panelOrientation}`).toLowerCase();
    },
    isMixedPanelType() {
      return this.panelOrientation === PANEL_ORIENTATION.MIESZANE;
    },
    sumOfAllRows() {
      const mappedVerticalRows = this.verticalRows.map((row) => parseFloat(row.modulesNumber));
      const mappedHorizontalRows = this.horizontalRows.map((row) => parseFloat(row.modulesNumber));
      const sumOfAll = sum(mappedVerticalRows) + sum(mappedHorizontalRows);
      const anyNegative =
        mappedVerticalRows.some((row) => row < 0) || mappedHorizontalRows.some((row) => row < 0);
      return anyNegative
        ? VueI18n.t('components.wizard.moduleNumber.incorrectQuantity')
        : sumOfAll >= 0
        ? sumOfAll
        : VueI18n.t('components.wizard.moduleNumber.incorrectQuantity');
    },
    validators() {
      return validators;
    },
  },
  methods: {
    sumOfModules() {
      const sum =
        this.wizardType.name === wizardTypes.assistant.name
          ? this.sumOfModulesAssistant()
          : this.sumOfModulesConstructionCalculator();

      return sum;
    },
    sumOfModulesConstructionCalculator() {
      return this.constructionCalculatorAttributes.quantity;
    },
    sumOfModulesAssistant() {
      const selectedModules = this.$store.state.wizardOptions.modules;
      let sum = 0;
      for (const module of selectedModules) {
        sum += module.quantity;
      }
      return sum;
    },
    validate() {
      this.$refs.moduleNumberForm.validate();
      return this.sumOfModules() === this.sumOfAllRows;
    },
    isEnabledFoward(val) {
      if (this.validate() && val) {
        this.$emit('setIsForwardEnabled', true);

        this.$emit('showBeforeFoward', false, {
          message: '',
        });
      } else {
        this.$emit('setIsForwardEnabled', false);

        this.$emit('showBeforeFoward', true, {
          message: VueI18n.t('components.wizard.moduleNumber.toasted'),
        });
      }
    },
    setupRows() {
      this.verticalRows = [];
      this.horizontalRows = [];

      for (let i = 1; i < this.verticalRowNumber + 1; i++) {
        this.verticalRows.push({
          index: i,
          modulesNumber: 0,
        });
      }

      for (let i = 1; i < this.horizontalRowNumber + 1; i++) {
        this.horizontalRows.push({
          index: i,
          modulesNumber: 0,
        });
      }
    },
  },
  watch: {
    sumOfAllRows: function (val) {
      if (val >= 0) {
        const mappedVerticalModules = this.verticalRows.map((row) => {
          return {
            index: row.index,
            modulesNumber: parseFloat(row.modulesNumber),
          };
        });
        const mappedHorizontalModules = this.horizontalRows.map((row) => {
          return {
            index: row.index,
            modulesNumber: parseFloat(row.modulesNumber),
          };
        });
        this.$store.commit(this.storeActionVerticalModules, mappedVerticalModules);
        this.$store.commit(this.storeActionHorizontalModules, mappedHorizontalModules);
      }
      this.isEnabledFoward(this.isValid);
    },

    isValid: function (val) {
      this.isEnabledFoward(val);
    },
  },
  created() {
    this.setupRows();
    this.$emit('setIsForwardEnabled', false);
  },
  activated() {
    this.verticalRows = [];
    this.horizontalRows = [];

    if (this.verticalModulesNumbers?.length || this.horizontalModulesNumbers?.length) {
      this.verticalRows = [...this.verticalModulesNumbers];
      this.horizontalRows = [...this.horizontalModulesNumbers];
      this.isEnabledFoward(this.isValid);
    } else {
      this.setupRows();
    }
  },
};
</script>
<style lang="scss" scoped></style>
