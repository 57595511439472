<template>
  <v-container>
    <v-row class="mb-1">
      <h3 class="text">{{ $t('components.deleteAccount.title') }}</h3>
    </v-row>
    <v-row>
      <p class="text-sm grey--text">{{ $t('components.deleteAccount.warning') }}</p>
    </v-row>
    <v-row>
      <primary-button color="error-primary" :onClick="openDeleteModal">{{
        $t('components.deleteAccount.delete')
      }}</primary-button>
    </v-row>
    <confirm-modal
      :open="openModal"
      :title="$t('components.deleteAccount.modal.title')"
      :agree="$t('components.deleteAccount.modal.agree')"
      :disagree="$t('components.deleteAccount.modal.disagree')"
      color="error-primary"
      v-on:decline="decline"
      v-on:accept="accept"
      :loading="loading"
      >{{ $t('components.deleteAccount.modal.text') }}</confirm-modal
    >
  </v-container>
</template>
<script>
import UserService from '../services/UserService';
import PrimaryButton from './buttons/PrimaryButton.vue';
import ConfirmModal from './modals/ConfirmModal.vue';
import { userDataComputed } from '../store/helper';
import VueI18n from '../plugins/i18n';

export default {
  components: { PrimaryButton, ConfirmModal },
  name: 'DeleteAccount',
  data() {
    return {
      openModal: false,
      loading: false,
    };
  },
  methods: {
    openDeleteModal() {
      this.openModal = true;
    },
    async accept() {
      try {
        this.loading = true;
        UserService.deleteAccount(this.userData._id);
        this.openModal = false;
        this.$toasted.global.success({
          message: VueI18n.t('components.deleteAccount.toasted.success'),
        });
        this.$store.commit('logout');
      } catch (err) {
        this.$toasted.global.error({
          message: VueI18n.t('components.deleteAccount.toasted.error'),
        });
        console.error(err);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    decline() {
      this.openModal = false;
    },
  },
  computed: {
    ...userDataComputed,
  },
};
</script>
<style lang="scss" scoped></style>
