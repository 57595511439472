<template>
  <title-wrapper :title="$t('components.wizard.screwType.title')">
    <clickable-section
      :items="isPitched() ? handleTypes[pourType] : handleTypes[tscTypes.Sliding]"
      @updateSelected="updateSelected"
      :active="selectedScrewType"
    ></clickable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import ClickableSection from '../../../components/ClickableSection.vue';
import { screwTypeComputed, pourTypeComputed, roofTypeComputed } from '../../../store/helper.js';
import { SCREW_TYPES, TSC_TYPES, ROOF_TYPE } from '../../../static/fotoConstants.js';
import srubaZGwintemPodwojnym from '@/assets/wizzardImages/srubaZGwintemPodwojnym.png';

export default {
  name: 'ScrewType',
  components: { TitleWrapper, ClickableSection },
  data() {
    return {
      selectedScrewType: '',
      isValid: false,
      storeAction: 'setScrewType',
      handleTypes: {
        Blachodachówka: [
          {
            value: SCREW_TYPES.M10200,
            text: SCREW_TYPES.M10200,
            imgPath: srubaZGwintemPodwojnym,
          },
          {
            value: SCREW_TYPES.M10250,
            text: SCREW_TYPES.M10250,
            imgPath: srubaZGwintemPodwojnym,
          },
          {
            value: SCREW_TYPES.M10300,
            text: SCREW_TYPES.M10300,
            imgPath: srubaZGwintemPodwojnym,
          },
        ],
        sliding: [
          {
            value: SCREW_TYPES.M10200,
            text: SCREW_TYPES.M10200,
            imgPath: srubaZGwintemPodwojnym,
          },
        ],
      },
    };
  },
  watch: {
    isValid: function (val) {
      this.$emit('setIsForwardEnabled', val);
    },
  },
  methods: {
    updateSelected(screwType) {
      this.selectedScrewType = screwType;
      this.$store.commit(this.storeAction, this.selectedScrewType);
      this.isValid = true;
    },
    isPitched() {
      return this.roofType === ROOF_TYPE.PitchedRoof;
    },

    isFlat() {
      return this.roofType === ROOF_TYPE.FlatRoof;
    },
  },
  computed: {
    ...screwTypeComputed,
    ...pourTypeComputed,
    ...roofTypeComputed,

    tscTypes() {
      return TSC_TYPES;
    },
  },
  activated() {
    if (this.selectedScrewType) {
      this.$emit('setIsForwardEnabled', true);
    } else {
      this.$emit('setIsForwardEnabled', false);
    }
  },
  mounted() {
    if (this.screwType) {
      this.selectedScrewType = this.screwType;
      this.isValid = true;
    }

    if (this.isFlat()) {
      this.updateSelected(TSC_TYPES.Sliding);

      this.$emit('setIsForwardEnabled', true);
      this.$emit('goForward');
    }
  },
};
</script>
<style lang="scss" scoped></style>
