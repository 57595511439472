<template>
  <v-container>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">{{ $t('components.changePassword.current') }}*</h3>
          <v-text-field
            v-model="old_password"
            :append-icon="show_old_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_old_password = !show_old_password"
            :type="show_old_password ? 'text' : 'password'"
            name="old_password"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">{{ $t('components.changePassword.new') }}*</h3>
          <v-text-field
            v-model="new_password"
            :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="validators.passwordRules"
            @click:append="show_new_password = !show_new_password"
            :type="show_new_password ? 'text' : 'password'"
            name="new_password"
            @change="validatePassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">{{ $t('components.changePassword.repeat') }}*</h3>
          <v-text-field
            v-model="confirmation_password"
            :append-icon="show_confirmation_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_confirmation_password = !show_confirmation_password"
            :rules="[
              ...validators.passwordRules,
              (value) => validators.samePasswordRule(value, new_password),
            ]"
            :type="show_confirmation_password ? 'text' : 'password'"
            name="confirmation_password"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <primary-button :onClick="changePassword" :loading="loading">{{
            $t('components.changePassword.save')
          }}</primary-button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { validators } from '../validators/Validators';
import UserService from '../services/UserService';
import { userDataComputed } from '../store/helper';
import { formMixin } from '../mixins/formMixin';

export default {
  components: { PrimaryButton },
  name: 'ChangePassword',
  mixins: [formMixin],
  data() {
    return {
      isFormValid: false,
      old_password: '',
      new_password: '',
      confirmation_password: '',
      show_old_password: false,
      show_new_password: false,
      show_confirmation_password: false,
      loading: false,
    };
  },
  methods: {
    async changePassword() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          this.loading = true;
          await UserService.changePassword(
            this.userData?._id,
            this.new_password,
            this.old_password
          );
          this.$toasted.global.success({
            message: this.$i18n.t('components.changePassword.toasted'),
          });
          this.resetPasswords();
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
    resetPasswords() {
      this.old_password = '';
      this.new_password = '';
      this.confirmation_password = '';
      this.$refs.form.resetValidation();
    },
    validatePassword() {
      this.$refs.form.validate();
    },
  },
  computed: {
    validators() {
      return validators;
    },
    ...userDataComputed,
  },
};
</script>
<style lang="scss" scoped></style>
