import { render, staticRenderFns } from "./Optimizers.vue?vue&type=template&id=3b6fa010&scoped=true&"
import script from "./Optimizers.vue?vue&type=script&lang=js&"
export * from "./Optimizers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6fa010",
  null
  
)

export default component.exports