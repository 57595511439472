<template>
  <v-container>
    <v-form v-model="isFormValid" ref="form">
      <v-row class="mb-4">
        <h2 class="text">{{ title }}</h2>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="3">
          <v-text-field
            v-model="input"
            elevation="0"
            :label="label"
            :rules="validators"
            outlined
            solo
          ></v-text-field>
          <primary-button :onClick="emitInput" :loading="loading">{{
            $t('helpers.saveChanges')
          }}</primary-button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { formMixin } from '../mixins/formMixin';

export default {
  components: { PrimaryButton },
  name: 'EditAccountItem',
  mixins: [formMixin],
  props: ['title', 'label', 'loading', 'value', 'validators'],
  data() {
    return {
      input: '',
      isFormValid: false,
    };
  },
  methods: {
    emitInput() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        this.$emit('click', this.input);
      }
    },
  },
  created() {
    this.input = this.value;
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/main.scss';
</style>
