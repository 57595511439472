<template>
  <title-wrapper :title="$t('components.wizard.modulesQuantity.title')">
    <v-card tile class="px-7 pt-5 pb-5">
      <v-row>
        <v-col cols="12">
          <h2 class="text">{{ $t('components.wizard.modulesQuantity.quantityDesc') }}</h2>
          <v-form v-model="isFormValid" ref="modulesQuantityForm" class="mt-6">
            <v-container class="pa-0 ma-0">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    class="text-h6"
                    name="modulesQuantity"
                    :label="$t('components.wizard.modulesQuantity.title')"
                    :rules="validators.modulesQuantityRules"
                    type="number"
                    hide-details="auto"
                    v-model="quantity"
                    outlined
                    elavation="0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    class="text-h6"
                    :items="avaiableModuleThicknesses"
                    :rules="validators.modulesThicknessRules"
                    v-model="thickness"
                    :label="$t('components.wizard.modulesQuantity.thickness')"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    class="text-h6"
                    name="length"
                    :label="$t('components.wizard.roofType.modulesLength')"
                    :rules="validators.modulesLengthRules"
                    type="number"
                    suffix="cm"
                    hide-details="auto"
                    v-model="length"
                    outlined
                    elavation="0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    class="text-h6"
                    name="height"
                    :label="$t('components.wizard.roofType.modulesHeight')"
                    :rules="validators.modulesHeightRules"
                    type="number"
                    suffix="cm"
                    hide-details="auto"
                    v-model="height"
                    outlined
                    elavation="0"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row> </v-card
  ></title-wrapper>
</template>

<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import PhotovoltaicModules from '../../../static/categories/PhotovoltaicModules';
import { constructionCalculatorAttributesComputed } from '../../../store/helper';
import { validators } from '../../../validators/Validators';

export default {
  name: 'ModulesQuantity',
  components: { TitleWrapper },
  data() {
    return {
      isFormValid: false,
      quantity: 0,
      thickness: 0,
      length: 0,
      height: 0,
      type: {
        name: 'ModulesQuantity',
        isRoofConstruction: false,
      },
    };
  },
  computed: {
    ...constructionCalculatorAttributesComputed,
    avaiableModuleThicknesses() {
      const baseModule = new PhotovoltaicModules();
      return baseModule.attributes.find((attr) => attr.name === 'Grubość modułu').options;
    },
    validators() {
      return validators;
    },
  },
  methods: {
    validate() {
      this.$refs.modulesQuantityForm.validate();
    },
    setValuesFromStore() {
      this.quantity = +this.constructionCalculatorAttributes.quantity;
      this.thickness = +this.constructionCalculatorAttributes.thickness;
      this.length = +this.constructionCalculatorAttributes.length;
      this.height = +this.constructionCalculatorAttributes.height;
    },
    updateStoreAndValidateConstructionValues(setOption, newVal) {
      this.$store.commit(setOption, parseFloat(newVal));
      this.$emit('setIsForwardEnabled', this.isFormValid);
    },
  },
  watch: {
    isFormValid: function (val) {
      this.$emit('setIsForwardEnabled', val);
    },
    quantity(newVal) {
      this.updateStoreAndValidateConstructionValues('setQuantity', newVal);
    },
    thickness(newVal) {
      this.updateStoreAndValidateConstructionValues('setThickness', newVal);
    },
    length(newVal) {
      this.updateStoreAndValidateConstructionValues('setLength', newVal);
    },
    height(newVal) {
      this.updateStoreAndValidateConstructionValues('setHeight', newVal);
    },
    constructionCalculatorAttributes() {
      this.setValuesFromStore();
    },
  },
  activated() {
    this.$emit('setIsForwardEnabled', this.isFormValid);
  },
  mounted() {
    this.setValuesFromStore();
  },
};
</script>

<style></style>
