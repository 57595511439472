<template>
  <title-wrapper :title="$t('components.wizard.clampType.title')">
    <selectable-section
      :label="$t('components.wizard.clampType.selectLabel')"
      :items="clampTypes"
      storeAction="setClampType"
      :active="clampType"
      :preview="false"
    ></selectable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import SelectableSection from '../../../components/SelectableSection.vue';
import { clampTypeComputed } from '../../../store/helper.js';
import { CLAMP_TYPES } from '../../../static/fotoConstants.js';
import VueI18n from '../../../plugins/i18n';

export default {
  name: 'ClampType',
  components: { TitleWrapper, SelectableSection },
  data() {
    return {};
  },
  computed: {
    clampTypes() {
      const clampTypes = [
        {
          value: CLAMP_TYPES.SREBRNE,
          text: VueI18n.t('statics.clampTypes.SREBRNE'),
        },
        {
          value: CLAMP_TYPES.CZARNE,
          text: VueI18n.t('statics.clampTypes.CZARNE'),
        },
      ];
      return clampTypes;
    },
    ...clampTypeComputed,
  },
  activated() {
    this.$emit('setIsForwardEnabled', true);
  },
};
</script>
<style lang="scss" scoped></style>
