<template>
  <v-container class="pa-0">
    <v-row>
      <v-col v-if="isOneItem(items)">
        <v-card
          tile
          class="pa-5"
          :class="isActive(items[0].value)"
          @click="onClick(items[0].value)"
        >
          <v-img
            :src="items[0].imgPath"
            contain
            class="mx-auto my-3"
            max-width="250"
            max-height="300"
          ></v-img>
          <p class="text-center">{{ items[0].text }}</p>
        </v-card>
      </v-col>
      <v-col v-else :class="`col-${cols}`" v-for="item in items" :key="item.value">
        <v-card tile class="pa-5" :class="isActive(item.value)" @click="onClick(item.value)">
          <v-img
            :src="item.imgPath"
            contain
            class="mx-auto my-3"
            max-width="150"
            max-height="250"
          ></v-img>
          {{ item.text }}
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ClickableSection',
  props: {
    items: Array,
    active: String,
    cols: {
      type: String,
      default: '4',
    },
  },
  methods: {
    updateSelected(newSelected) {
      this.$emit('updateSelected', newSelected);
    },
    onClick(newSelected) {
      this.updateSelected(newSelected);
    },
    isActive(name) {
      return this.active === name ? 'selected' : '';
    },
    isOneItem() {
      return this.items.length === 1;
    },
    ifSingleHandleTypeUpdateSelected() {
      if (this.items.length === 1) {
        this.updateSelected(this.items[0].value);
      }
    },
  },
  activated() {
    this.ifSingleHandleTypeUpdateSelected();
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/variables';
.v-card {
  cursor: pointer;
  &.selected {
    transition: none;
    border-bottom: 0.125rem solid $primary;
    &:hover {
      transform: none;
    }
  }
  transition: all 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 9px -2px rgba(0, 0, 0, 0.3);
  }
}
</style>
