import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/wizard`;

axios.defaults.withCredentials = true;

class WizardService {
  static async getAccessories(wizardOptions) {
    const res = await axios.post(`${url}/accessories`, { wizardOptions });
    return res.data;
  }
}

export default WizardService;
