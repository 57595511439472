<template>
  <v-container class="d-flex flex-column align-center justify-center minHeight">
    <v-row class="mt-12 mb-5">
      <v-col class="text-center">
        <h1 class="title">{{ $t('components.wizard.startWizard.title') }}</h1>
        <p class="text mt-2">{{ $t('components.wizard.startWizard.info') }}:</p>
      </v-col>
    </v-row>
    <primary-button class="mb-4" :onClick="toWizard">{{
      $t('components.wizard.startWizard.begin')
    }}</primary-button>
    <confirm-modal
      :title="$t('components.wizard.startWizard.modal.title')"
      @accept="accept"
      @decline="disagree"
      @optionalAction="saveOffer"
      :showOptionalAction="true"
      :open="showModal"
      :disagree="$t('components.wizard.startWizard.modal.disagree')"
      :optionalActionLabel="$t('components.wizard.startWizard.modal.optionalLabel')"
      :agree="$t('components.wizard.startWizard.modal.agree')"
      >{{ $t('components.wizard.startWizard.modal.text') }}
    </confirm-modal>
  </v-container>
</template>
<script>
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
import ConfirmModal from '../../components/modals/ConfirmModal.vue';
import { checkoutModes } from '../../static/checkoutModes.js';
import {
  cartComputed,
  organizationComputed,
  selectedCurrencyObjComputed,
  wizardTypeComputed,
} from '../../store/helper.js';
import OfferService from '../../services/OfferService';
import VueI18n from '../../plugins/i18n';

export default {
  name: 'StartWizard',
  components: { PrimaryButton, ConfirmModal },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...cartComputed,
    ...organizationComputed,
    ...selectedCurrencyObjComputed,
    ...wizardTypeComputed,
  },
  methods: {
    toWizard() {
      if (this.cart.length) {
        return (this.showModal = true);
      }
      this.startWizard();
    },
    resetWizard() {
      this.resetWizardStore();
      this.$toasted.global.success(VueI18n.t('components.startWizard.toasted'));
    },
    resumeWizard() {
      this.$emit('navToCurrentStep');
    },
    resetWizardStore() {
      this.$store.commit('resetWizardBeforeStart');
    },
    async startWizard() {
      this.resetWizardStore();
      this.$store.commit('setMode', checkoutModes.WIZARD);
      this.$router.push({ name: this.wizardType.startingPath });
    },
    isReturnVisible() {},
    isWizardInProgress() {
      const step = this.$store.state.wizardStep;
      const subStep = this.$store.state.wizardSubStep;

      if (step > 0 || subStep > 0) {
        return true;
      } else {
        return false;
      }
    },
    accept() {
      this.showModal = false;
      this.startWizard();
    },
    disagree() {
      this.showModal = false;
    },
    async saveOffer() {
      await this.saveAsOffer();
      this.showModal = false;
      this.startWizard();
    },
    mapItemsToOffer(items) {
      return items.map((item) => ({
        product: item._id,
        quantity: item.quantity,
      }));
    },
    async saveAsOffer() {
      try {
        const mappedProducts = {
          productsRefs: this.mapItemsToOffer(this.cart),
          deliveryPrice: 0,
          currency: this.selectedCurrencyObj,
        };
        await OfferService.create(mappedProducts);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
