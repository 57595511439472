<template>
  <title-wrapper :title="$t('components.wizard.optimizers.title')">
    <catalog-list
      :category="categoriesObj.OPTIMIZERS.value"
      :filters="false"
      :onWizard="true"
      :addAction="addAction"
      :removeAction="removeAction"
      :isCompatibleWithPower="true"
    >
    </catalog-list>
    <multiple-compatibility-modal
      :remove-action="removeAction"
      incompatibility-step-action="addToIncompatibleSteps"
      :selected-items="selectedOptimizers"
      :wizard-step="wizardStep"
      :openModal="openModal"
      @update:openModal="updateOpenModal"
    ></multiple-compatibility-modal>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import CatalogList from '../../components/CatalogList.vue';
import { selectedOptimizersComputed } from '../../store/helper.js';
import { selectedInvertersComputed } from '../../store/helper.js';
import MultipleCompatibilityModal from '../../components/MultipleCompatibilityModal.vue';
import { categoriesComputedMixin } from '../../mixins/categoriesComputedMixin';

export default {
  name: 'Optimizers',
  components: { TitleWrapper, CatalogList, MultipleCompatibilityModal },
  mixins: [categoriesComputedMixin],
  data() {
    return {
      addAction: 'addOptimizer',
      removeAction: 'removeOptimizer',
      isRequired: false,
      wizardStep: 3,
      requiredWithInverter: 'SOLAR EDGE',
      openModal: false,
    };
  },
  methods: {
    updateOpenModal(value) {
      this.openModal = value;
    },
    handleIncompatibility() {
      if (
        this.selectedOptimizers.some((opt) => opt.incompatible) ||
        !this.isNumberCompatibile(this.selectedOptimizers)
      ) {
        this.$store.commit('addToIncompatibleSteps', this.wizardStep);
      } else {
        this.$store.commit('removeFromIncompatibleSteps', this.wizardStep);
      }
    },
    isNumberCompatibile(items) {
      return this.isRequired ? items.length === 1 : true;
    },
  },
  computed: {
    ...selectedOptimizersComputed,
    ...selectedInvertersComputed,
  },
  activated() {
    const selectedInverterProducer =
      this.selectedInverters.length === 1 ? this.selectedInverters[0].producer?.name : '';
    this.isRequired = selectedInverterProducer === this.requiredWithInverter;
    const valid = this.isRequired ? this.selectedOptimizers.length > 0 : !this.isRequired;
    this.$emit('setIsForwardEnabled', valid);
    this.handleIncompatibility();
  },
  watch: {
    selectedOptimizers: function (selected, old) {
      const oldLength = old.length;
      const length = selected.length;

      const valid = this.isRequired ? selected.length > 0 : !this.isRequired;
      this.$emit('setIsForwardEnabled', valid);
      this.handleIncompatibility();

      if (length > 1 && oldLength < length) {
        this.openModal = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
