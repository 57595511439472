<template>
  <div>
    <v-form>
      <v-select
        v-model="select"
        :items="items"
        :label="label"
        :menu-props="{ 'offset-y': true }"
        solo
        @change="onSelectionChange"
        return-object
      >
      </v-select>
    </v-form>
    <div class="select-preview white px-2 py-md-8 py-sm-2" v-if="select && preview">
      <v-img
        v-if="$vuetify.breakpoint.sm"
        max-width="280"
        contain
        class="mx-auto"
        height="200"
        :src="imgPath"
      ></v-img>
      <v-img v-else max-width="400" contain class="mx-auto" height="350" :src="imgPath"></v-img>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelectableSection',
  props: ['label', 'items', 'storeAction', 'active', 'preview'],
  data() {
    return {
      select: null,
    };
  },
  methods: {
    onSelectionChange() {
      this.$store.commit(this.storeAction, this.select.value);
    },
  },
  activated() {
    this.select = this.active
      ? this.items.find((item) => item.value === this.active)
      : this.items[0];
    this.onSelectionChange();
  },
  computed: {
    imgPath() {
      return this.select?.imgPath;
    },
  },
};
</script>
<style lang="scss" scoped></style>
