<template>
  <div>
    <p class="my-0 ml-4 text-caption tertiary-text--text">
      {{ $t('components.additionalOfferServices.componentTitle') }}
    </p>
    <v-row class="ma-0 pa-3 font--text table-row">
      <v-col>{{ $t('components.additionalOfferServices.number') }}</v-col>
      <v-col>{{ $t('components.additionalOfferServices.service') }}</v-col>
      <v-col>{{
        $t('components.additionalOfferServices.price', { currency: currencyLabel(exchangeRate) })
      }}</v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-form ref="form" v-model="servicesFormValid">
      <v-row
        class="ma-0 pa-2 table-row align-center"
        v-for="(service, index) in services"
        :key="index"
      >
        <v-col cols="1">{{ index + 1 }}</v-col>
        <v-col cols="5">
          <v-text-field
            v-model="service.name"
            :rules="validators.requiredRules"
            outlined
            hide-details
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="service.price"
            :rules="validators.priceGroupRules"
            outlined
            :suffix="currencyLabel(exchangeRate)"
            type="number"
            hide-details
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn class="mr-3 pa-0" icon @click="removeService(service)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-4">
      <v-col class="text-right">
        <primary-button :onClick="addService">
          <v-icon class="mr-3">mdi-plus-circle-outline</v-icon>
          {{ $t('components.additionalOfferServices.addPosition') }}
        </primary-button>
      </v-col>
    </v-row>
    <v-row v-if="totalServicesPrice">
      <v-col class="text-right">
        {{ $t('components.additionalOfferServices.priceAdditionalService') }}:
        {{ formatCurrency(totalServicesPrice, exchangeRate, false) }}</v-col
      >
    </v-row>
  </div>
</template>
<script>
import PrimaryButton from '../components/buttons/PrimaryButton.vue';
import { validators } from '../validators/Validators';
import { formatCurrency } from '../mixins/formatCurrency';
import { round, sumBy } from 'lodash';
import { formMixin } from '../mixins/formMixin';

export default {
  name: 'AdditionalOfferServices',
  components: { PrimaryButton },
  mixins: [formatCurrency, formMixin],
  props: {
    services: {
      type: Array,
    },
    exchangeRate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      servicesFormValid: false,
      validators,
    };
  },
  methods: {
    removeService(service) {
      const serviceIndex = this.services.indexOf(service);
      if (serviceIndex > -1) {
        this.services.splice(serviceIndex, 1);
        this.$emit('onRemoveService');
      }
    },
    addService() {
      this.services.push({
        name: '',
        price: 0,
      });
    },
  },
  computed: {
    totalServicesPrice() {
      const sum = round(
        sumBy(
          this.services.map((service) => {
            return {
              ...service,
              price: parseFloat(service.price),
            };
          }),
          'price'
        ),
        2
      );
      return parseFloat(sum || 0);
    },
  },
};
</script>
