<template>
  <title-wrapper :title="$t('components.editAccount.title')">
    <collapse :title="$t('components.editAccount.colapseOneTitle')">
      <edit-account-item
        :title="$t('helpers.phoneNumber')"
        :label="$t('helpers.phoneNumber')"
        :value="userData ? userData.phone : ''"
        v-on:click="changePhone"
        :loading="loadingPhone"
        :validators="validators.phoneRules"
      ></edit-account-item
    ></collapse>
    <collapse :title="$t('components.editAccount.colapseTwo.colapseTitle')">
      <edit-account-item
        :title="$t('components.editAccount.colapseTwo.title')"
        :label="$t('helpers.email')"
        :value="userData ? userData.email : ''"
        :loading="loadingEmail"
        v-on:click="changeEmail"
        :validators="validators.emailRules"
      ></edit-account-item
    ></collapse>
    <collapse :title="$t('components.editAccount.colapseThreeTitle')">
      <change-password></change-password
    ></collapse>
    <collapse :title="$t('components.editAccount.colapseFourTitle')">
      <delete-account></delete-account
    ></collapse>
  </title-wrapper>
</template>
<script>
import UserService from '../../services/UserService';
import EditAccountItem from '../../components/EditAccountItem.vue';
import Collapse from '../../components/Collapse.vue';
import ChangePassword from '../../components/ChangePassword.vue';
import DeleteAccount from '../../components/DeleteAccount.vue';
import { userDataComputed } from '../../store/helper';
import { validators } from '../../validators/Validators';
import TitleWrapper from '../../components/TitleWrapper.vue';
import VueI18n from '../../plugins/i18n';

export default {
  name: 'EditAccount',
  components: { Collapse, EditAccountItem, ChangePassword, DeleteAccount, TitleWrapper },
  data() {
    return {
      loadingPhone: false,
      loadingEmail: false,
    };
  },
  methods: {
    async changeEmail(email) {
      try {
        this.loadingEmail = true;
        await UserService.update(this.userData?._id, { email });
        this.$toasted.global.success({
          message: VueI18n.t('components.editAccount.toasted.changeEmailFunc'),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingEmail = false;
      }
    },
    async changePhone(phone) {
      try {
        this.loadingPhone = true;
        await UserService.update(this.userData?._id, { phone });
        this.$toasted.global.success({
          message: VueI18n.t('components.editAccount.toasted.changePhoneFunc'),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingPhone = false;
      }
    },
  },
  computed: {
    ...userDataComputed,
    validators() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
