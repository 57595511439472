<template>
  <title-wrapper :title="title">
    <selectable-section
      :label="$t('components.wizard.pourType.selectLabel')"
      :items="items"
      :storeAction="storeAction"
      :active="activeItem"
      :preview="true"
    ></selectable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import SelectableSection from '../../../components/SelectableSection.vue';
import {
  installationMethodComputed,
  pourTypeComputed,
  roofTypeComputed,
} from '../../../store/helper.js';
import { INSTALLATION_METHODS, POUR_TYPE, ROOF_TYPE } from '../../../static/fotoConstants.js';
import pokrycieBlachodachowka from '@/assets/wizzardImages/pokrycieBlachodachowka.png';
import pokrycieBlachaTrapezowa from '@/assets/wizzardImages/pokrycieBlachaTrapezowa.png';
import pokrycieCeramika from '@/assets/wizzardImages/pokrycieCeramika.png';
import pokrycieKarpiowka from '@/assets/wizzardImages/pokrycieKarpiowka.png';
import pokrycieGont from '@/assets/wizzardImages/pokrycieGont.png';
import pokrycieRabek from '@/assets/wizzardImages/pokrycieRabek.png';
import invasive from '@/assets/wizzardImages/inwazyjna.png';
import noninvasive from '@/assets/wizzardImages/bezinwazyjna.png';
import VueI18n from '../../../plugins/i18n';

export default {
  name: 'PourType',
  components: { TitleWrapper, SelectableSection },
  data() {
    return {
      pourTypes: [
        {
          value: POUR_TYPE.BLACHODACHOWKA,
          text: VueI18n.t('statics.pourType.BLACHODACHOWKA'),
          imgPath: pokrycieBlachodachowka,
        },
        {
          value: POUR_TYPE.BLACHA_TRAPEZOWA,
          text: VueI18n.t('statics.pourType.BLACHA_TRAPEZOWA'),
          imgPath: pokrycieBlachaTrapezowa,
        },
        {
          value: POUR_TYPE.DACHOWKA_CERAMICZNA,
          text: VueI18n.t('statics.pourType.DACHOWKA_CERAMICZNA'),
          imgPath: pokrycieCeramika,
        },
        {
          value: POUR_TYPE.KARPIOWKA,
          text: VueI18n.t('statics.pourType.KARPIOWKA'),
          imgPath: pokrycieKarpiowka,
        },
        {
          value: POUR_TYPE.GONT,
          text: VueI18n.t('statics.pourType.GONT'),
          imgPath: pokrycieGont,
        },
        {
          value: POUR_TYPE.RABEK,
          text: VueI18n.t('statics.pourType.RABEK'),
          imgPath: pokrycieRabek,
        },
      ],
      installationMethods: [
        {
          value: INSTALLATION_METHODS.Invasive,
          text: VueI18n.t(`statics.installationMethods.${INSTALLATION_METHODS.Invasive}`),
          imgPath: invasive,
        },
        {
          value: INSTALLATION_METHODS.NonIvasive,
          text: VueI18n.t(`statics.installationMethods.${INSTALLATION_METHODS.NonIvasive}`),
          imgPath: noninvasive,
        },
      ],
    };
  },
  activated() {
    this.$emit('setIsForwardEnabled', true);
  },
  computed: {
    ...pourTypeComputed,
    ...roofTypeComputed,
    ...installationMethodComputed,
    title() {
      return this.isPitched
        ? this.$i18n.t('components.wizard.pourType.title')
        : this.$i18n.t('components.wizard.installationMethod.title');
    },
    items() {
      return this.isPitched ? this.pourTypes : this.installationMethods;
    },
    isPitched() {
      return this.roofType === ROOF_TYPE.PitchedRoof;
    },
    storeAction() {
      return this.isPitched ? 'setPourType' : 'setInstallationMethod';
    },
    activeItem() {
      return this.isPitched ? this.pourType : this.installationMethod;
    },
  },
};
</script>
<style lang="scss" scoped></style>
