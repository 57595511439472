<template>
  <v-dialog
    transition="dialog-bottom-transition"
    class="rounded-0"
    v-model="open"
    persistend
    @click:outside="onClickOutside"
    max-width="400"
  >
    <v-card class="text-center pt-4 pb-6 px-8" tile>
      <div class="text-right">
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <h2 class="card-title primary--text">{{ $t('components.flatRoofInfoModal.textOne') }}</h2>
      <p class="font-weight-light mt-2">
        {{ text }}
      </p>
      <v-divider></v-divider>
      <h3 class="primary--text mt-3 mb-3">{{ $t('components.flatRoofInfoModal.textThree') }}</h3>
      <div class="mt-3 d-flex justify-center align-center">
        <v-icon class="mr-2">$phoneIcon</v-icon>
        <span class="tertiary-text--text"> +48 675 567 677 </span>
      </div>
      <div class="mt-3 d-flex justify-center align-center">
        <v-icon class="mr-2">$mailIcon</v-icon>
        <span class="tertiary-text--text"> kontakt@solitan.pl </span>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onClickOutside() {
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped></style>
