<template>
  <v-container>
    <div class="white text-center pa-6">
      <h2 v-if="checkCompatibility">
        <v-icon class="mb-1" color="success-primary" x-large>mdi-check</v-icon>
        {{ $t('components.wizard.wizardSummary.textOne') }}
      </h2>
      <h2 v-else>
        <v-icon class="mb-1" color="warning" x-large>mdi-alert</v-icon>
        {{ $t('components.wizard.wizardSummary.textTwo') }}
      </h2>
      <p class="my-0 mt-5 subtitle-1 text-center">
        {{ $t('components.wizard.wizardSummary.textThree') }}
        <span class="font-weight-bold">{{ $t('components.wizard.wizardSummary.textFour') }}</span>
        {{ $t('components.wizard.wizardSummary.textFive') }}
      </p>
    </div>
    <v-row>
      <v-col>
        <product-list
          :items="cart"
          :isButtonAddVisible="false"
          :onBasicSummary="true"
        ></product-list>
      </v-col>
    </v-row>
    <additional-offer-services
      ref="offerServices"
      v-if="isCreatingOfferForExternalOrg"
      class="mt-3 white pa-3"
      :services="services"
      :exchangeRate="selectedCurrencyObj"
    ></additional-offer-services>
  </v-container>
</template>
<script>
import ProductList from '../../components/itemList/ProductList.vue';
import AdditionalOfferServices from '../../components/AdditionalOfferServices.vue';
import {
  cartComputed,
  organizationComputed,
  selectedCurrencyObjComputed,
} from '../../store/helper.js';
export default {
  components: { ProductList, AdditionalOfferServices },
  computed: {
    ...cartComputed,
    ...organizationComputed,
    ...selectedCurrencyObjComputed,
    checkCompatibility() {
      if (
        this.$store.state.wizardOptions.incompatibleSteps.length > 0 ||
        this.$store.state.wizardOptions.incompatibleSubSteps.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    isCreatingOfferForExternalOrg() {
      return !!this.organization.name;
    },
  },
  data() {
    return {
      services: [],
    };
  },
  activated() {
    this.$emit('setIsForwardEnabled', true);
  },
};
</script>
