<template>
  <title-wrapper :title="$t('components.wizard.declaredPower.title')">
    <v-card tile class="px-7 pt-5 pb-5">
      <v-row>
        <v-col cols="12">
          <h2 class="text">{{ $t('components.wizard.declaredPower.typePower') }}</h2>
          <v-form v-model="isFormValid" ref="declaredPowerForm" class="mt-6">
            <v-container class="pa-0 ma-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    class="text-h6"
                    name="declaredPower"
                    :label="$t('components.wizard.declaredPower.declaredPower')"
                    type="number"
                    hide-details="auto"
                    suffix="kW"
                    :rules="validators.declaredPowerRules"
                    v-model="power"
                    outlined
                    solo
                    elavation="0"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-sm">
                  {{ $t('components.wizard.declaredPower.warning') }}
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import { validators } from '../../validators/Validators';
import { declaredPowerComputed } from '../../store/helper.js';

export default {
  name: 'DeclaredPower',
  components: { TitleWrapper },
  data() {
    return {
      isFormValid: false,
      power: 0,
      storeAction: 'setDeclaredPower',
      type: {
        name: 'DeclaredPower',
        isRoofConstruction: false,
      },
    };
  },
  mounted() {
    if (this.declaredPower) {
      this.power = this.declaredPower;
    }
  },
  methods: {
    validate() {
      this.$refs.declaredPowerForm.validate();
    },
  },
  computed: {
    ...declaredPowerComputed,
    validators() {
      return validators;
    },
  },
  watch: {
    isFormValid: function (val) {
      this.$emit('setIsForwardEnabled', val);
    },
    power: function (val) {
      const validPower = this.$refs.declaredPowerForm.validate();
      if (parseFloat(val) > 0 && validPower) {
        this.$store.commit(this.storeAction, parseFloat(val));
        this.$emit('setIsForwardEnabled', true);
      }
    },
    declaredPower() {
      this.power = this.declaredPower;
    },
  },
  activated() {
    if (parseFloat(this.power) > 0) {
      this.$emit('setIsForwardEnabled', true);
    } else {
      this.$emit('setIsForwardEnabled', false);
    }
  },
};
</script>
<style lang="scss" scoped></style>
