<template>
  <title-wrapper :title="$t('components.wizard.roofType.title')">
    <v-container class="mx-0 px-0"
      ><v-row class="flex-column-reverse flex-sm-row">
        <v-col cols="12" sm="4">
          <v-card
            @click="markAsSelected(roofType.PitchedRoof)"
            class="white my-card text-center"
            :class="{ selected: selectedRoofType === roofType.PitchedRoof }"
          >
            <v-icon size="85" class="mb-8">$triangleIcon</v-icon>
            <h2 class="font-weight-bold">{{ $t('components.wizard.roofType.pitchedRoof') }}</h2>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card
            @click="markAsSelected(roofType.FlatRoof)"
            class="white my-card text-center"
            :class="{ selected: selectedRoofType === roofType.FlatRoof }"
          >
            <v-icon size="85" class="mb-8">$squareIcon</v-icon>
            <h2 class="font-weight-bold">{{ $t('components.wizard.roofType.flatRoof') }}</h2>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card @click="openInfoModal" class="white my-card text-center card-dimmed">
            <v-icon size="85" class="mb-8" color="primary">mdi-triangle-outline</v-icon>
            <h2 class="font-weight-bold">{{ $t('components.wizard.roofType.ground') }}</h2>
          </v-card>
        </v-col>
      </v-row>
      <roof-info-modal
        v-on:close="close"
        :open="openModal"
        :text="$t('components.flatRoofInfoModal.textTwo')"
      ></roof-info-modal>
    </v-container>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import RoofInfoModal from '../../../components/modals/RoofInfoModal.vue';
import { ROOF_TYPE } from '../../../static/fotoConstants.js';
import { permissionRolesMixin } from '../../../mixins/permissionRolesMixin';
import { validators } from '../../../validators/Validators';
import { wizardTypeComputed } from '../../../store/helper';
import { wizardTypes } from '../../../static/wizardTypes';

export default {
  name: 'RoofType',
  components: { TitleWrapper, RoofInfoModal },
  mixins: [permissionRolesMixin],
  data() {
    return {
      selectedRoofType: ROOF_TYPE.PitchedRoof,
      openModal: false,
      isFormValid: false,
    };
  },
  async activated() {
    await this.markAsSelected(this.selectedRoofType);
    this.$emit('setIsForwardEnabled', true);
  },
  methods: {
    updateStoreAndValidateConstructionValues(setOption, newVal) {
      this.$store.commit(setOption, parseFloat(newVal));
    },
    openInfoModal() {
      this.openModal = true;
    },
    async markAsSelected(roofType) {
      this.selectedRoofType = roofType;

      await this.$store.dispatch('resetPourType');

      await this.$store.dispatch('setRoofType', this.selectedRoofType);
    },
    close() {
      this.openModal = false;
    },
  },
  computed: {
    ...wizardTypeComputed,
    validators() {
      return validators;
    },
    wizardTypes() {
      return wizardTypes;
    },
    roofType() {
      return ROOF_TYPE;
    },
  },
  watch: {
    selectedRoofType(newVal) {
      if (newVal === ROOF_TYPE.PitchedRoof || newVal === ROOF_TYPE.FlatRoof) {
        this.$emit('setIsForwardEnabled', true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.card-dimmed {
  opacity: 0.4;
}
.my-card {
  transition: all 0.2s ease-out;
  padding: 3.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 11px 18px -2px rgba(0, 0, 0, 0.3);
  }
  &.selected {
    border-bottom: 3px solid $primary !important;
  }
}
</style>
