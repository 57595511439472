<template>
  <confirm-modal
    @decline="declineIncompatibility"
    @accept="acceptIncompatibility"
    :title="$t('components.multipleCompatibilityModal.title')"
    :open="openModal"
    :agree="$t('components.multipleCompatibilityModal.agree')"
  >
    {{ $t('components.multipleCompatibilityModal.warning') }}
  </confirm-modal>
</template>
<script>
import ConfirmModal from './modals/ConfirmModal.vue';

export default {
  name: 'MultipleCompatibilityModal',
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    removeAction: {
      type: String,
      required: true,
    },
    incompatibilityStepAction: {
      type: String,
      required: true,
      default: 'addToIncompatibleSteps',
    },
    wizardStep: {
      type: Number,
      required: true,
    },
  },
  components: {
    ConfirmModal,
  },
  methods: {
    declineIncompatibility() {
      const productId = this.selectedItems[this.selectedItems.length - 1]._id;
      this.$store.commit('removeFromCart', productId);
      this.$store.commit(this.removeAction, productId);
      this.$emit('update:openModal', false);
    },
    acceptIncompatibility() {
      this.$store.commit(this.incompatibilityStepAction, this.wizardStep);
      this.$emit('update:openModal', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
