<template>
  <div class="d-flex" :class="{ 'flex-column-reverse': isMobile }">
    <div>
      <v-card class="left ml-3" width="310" height="fit-content">
        <v-list>
          <div v-for="(step, index) in wizardSteps" :key="step.title">
            <v-list-item link :to="step.to" v-if="!step.children" :disabled="isDisabled(step)">
              <v-list-item-icon>
                <v-icon>{{ step.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >{{ step.title }}
                <span v-if="index < currentStep && !isDisabled(step)">
                  <v-icon color="warning" v-if="isStepIncompatible(step)">mdi-alert</v-icon>
                  <v-icon color="success-primary" v-if="!isStepIncompatible(step)"
                    >mdi-check</v-icon
                  >
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-group v-if="step.children" :value="isActive(step)">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ step.title }}
                    <span v-if="index < currentStep && !isDisabled(step)">
                      <v-icon color="warning" v-if="incompatibleSubSteps.length > 0"
                        >mdi-alert</v-icon
                      >
                      <v-icon color="success-primary" v-else>mdi-check</v-icon>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:prependIcon>
                <v-icon>{{ step.icon }}</v-icon>
              </template>
              <v-list-item
                class="ml-8"
                v-for="(child, childIndex) in step.children"
                :key="child.title"
                :to="child.to"
                link
                :disabled="isDisabledSubstep(step, child) || isCrossedSubstep(step, child)"
                :class="{ crossed: isCrossedSubstep(step, child) }"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ child.title }}
                  <span
                    v-if="
                      (childIndex === 9 || childIndex < currentSubStep) &&
                      !isDisabledSubstep(step, child) &&
                      !isCrossedSubstep(step, child)
                    "
                  >
                    <v-icon color="warning" v-if="isSubStepIncompatible(step, child)"
                      >mdi-alert</v-icon
                    >
                    <v-icon color="success-primary" v-if="!isSubStepIncompatible(step, child)"
                      >mdi-check</v-icon
                    >
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-card>
      <v-container class="mt-2">
        <p>
          <v-icon class="mb-1" color="success-primary">mdi-check</v-icon> -
          {{ $t('components.wizard.wizardIndex.setCompatible') }}
        </p>
        <p>
          <v-icon class="mb-1" color="warning">mdi-alert</v-icon> -
          {{ $t('components.wizard.wizardIndex.setIncompatible') }}
        </p>
      </v-container>
    </div>

    <v-container class="px-sm-8">
      <v-row>
        <keep-alive>
          <router-view
            ref="child"
            @showBeforeFoward="showBeforeFoward"
            @setIsForwardEnabled="setIsForwardEnabled"
            @navToCurrentStep="goToCurrentStep"
            @goForward="goForward"
          ></router-view>
        </keep-alive>
      </v-row>
      <v-row
        v-if="showButtons"
        class="flex-column-reverse flex-sm-row pa-0 ma-0 mt-4"
        style="max-width: 100%"
      >
        <v-col cols="12" sm="auto" class="pa-0 ma-0">
          <outlined-button :onClick="goBack">{{ $t('helpers.goBack') }}</outlined-button>
        </v-col>
        <v-spacer v-if="!isMobile"></v-spacer>
        <v-col :class="{ 'text-right': !isMobile }" cols="12" sm="auto" class="pa-0 ma-0">
          <primary-button
            v-if="isCreatingOfferForExternalOrg && isWizardSummary"
            :onClick="finishAndCreateOffer"
            >{{ $t('components.wizard.wizardIndex.endAndCreateOffer') }}</primary-button
          >
          <primary-button v-else :onClick="goForward">{{ btnText }}</primary-button>
        </v-col>
      </v-row>
    </v-container>
    <confirm-modal
      :title="$t('components.wizard.wizardIndex.modalOne.title')"
      @accept="confirmForward"
      @decline="disagree"
      :open="showModal"
      :agree="$t('components.wizard.wizardIndex.modalOne.agree')"
      >{{ showBeforeMessage }}</confirm-modal
    >
    <confirm-modal
      :title="$t('components.wizard.wizardIndex.modalTwo.title')"
      @accept="continueInStarmont"
      @decline="continueInSolitan"
      :open="offerCreatedModal"
      :agree="$t('components.wizard.wizardIndex.modalTwo.agree')"
      :disagree="$t('components.wizard.wizardIndex.modalTwo.disagree')"
    >
      {{ $t('components.wizard.wizardIndex.modalTwo.text') }}.
    </confirm-modal>
    <confirm-modal
      :title="$t('components.wizard.wizardIndex.modalFour.title')"
      @accept="resetWizard"
      @decline="keepWizard"
      :open="confirmResetModal.open"
      :agree="$t('components.wizard.wizardIndex.modalFour.agree')"
      >{{ $t('components.wizard.wizardIndex.modalFour.text') }}.</confirm-modal
    >
    <change-route-confirm-modal ref="confirm"></change-route-confirm-modal>
  </div>
</template>
<script>
import OutlinedButton from '../../components/buttons/OutlinedButton.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
import {
  pourTypeComputed,
  handleTypeComputed,
  incompatibleStepsComputed,
  incompatibleSubStepsComputed,
  declaredPowerChangedComputed,
  modeComputed,
  organizationComputed,
  cartComputed,
  selectedCurrencyObjComputed,
  wizardTypeComputed,
  confirmResetModal,
  roofTypeComputed,
  installationMethodComputed,
} from '@/store/helper';
import {
  POUR_TYPE,
  noHandleTypePourTypes,
  permamentPourTypes,
} from '../../static/fotoConstants.js';
import ConfirmModal from '../../components/modals/ConfirmModal.vue';
import ChangeRouteConfirmModal from '../../components/modals/ChangeRouteConfirmModal.vue';
import { checkoutModes } from '../../static/checkoutModes';
import OfferService from '../../services/OfferService';
import config from '../../../configuration.json';
import { wizardTypes } from '../../static/wizardTypes';
import { ROOF_TYPE } from '../../static/fotoConstants.js';
import { INSTALLATION_METHODS } from '@/static/fotoConstants';

export default {
  name: 'WizardIndex',
  components: {
    PrimaryButton,
    OutlinedButton,
    ConfirmModal,
    ChangeRouteConfirmModal,
  },
  async beforeRouteLeave(to, from, next) {
    const wizardRegex = 'doradca';
    if (
      !to.path.includes(wizardRegex) &&
      from.path.includes(wizardRegex) &&
      to.name !== 'TransactionSummary' &&
      this.mode === checkoutModes.WIZARD
    ) {
      const confirmed = await this.$refs.confirm.open(
        this.$i18n.t('components.wizard.wizardIndex.modalThree.title'),
        this.$i18n.t('components.wizard.wizardIndex.modalThree.text')
      );
      if (confirmed) {
        this.$store.commit('finishCreatingOfferForExternalClient');
        await this.$store.commit('resetWizard');
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      showButtons: false,
      isNextActive: false,
      showBefore: false,
      showBeforeMessage: '',
      showModal: false,
      wizardSummaryStep: 7,
      offerCreatedModal: false,
    };
  },
  watch: {
    $route(to) {
      if (to.name !== 'StartWizard') {
        const wizardStep = this.wizardSteps.find((el) => to.name === el.to.name);

        if (wizardStep) {
          const indexOfStep = this.wizardSteps.indexOf(wizardStep);
          this.$store.commit('setWizardStep', indexOfStep);
        } else {
          for (const step of this.wizardSteps) {
            const wizardSubStep = step.children?.find((el) => to.name === el.to.name);
            if (wizardSubStep) {
              this.$store.commit('setWizardStep', this.wizardSteps.indexOf(step));
              this.$store.commit('setWizardSubStep', step.children.indexOf(wizardSubStep));
            }
          }
        }
      }
      this.areButtonsVisible(to);
    },
  },
  computed: {
    wizardSteps() {
      const steps = [
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.declaredPower'),
          icon: 'mdi-solar-power',
          to: { name: 'DeclaredPower' },
          wizards: [wizardTypes.assistant],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.modules'),
          icon: 'mdi-view-module',
          to: { name: 'ModulesQuantity' },
          wizards: [wizardTypes.constructionCalculator],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.modules'),
          icon: 'mdi-view-module',
          to: { name: 'Modules' },
          wizards: [wizardTypes.assistant],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.inverters'),
          icon: 'mdi-invert-colors',
          to: { name: 'Inverters' },
          wizards: [wizardTypes.assistant],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.optimizers'),
          icon: 'mdi-arrow-collapse',
          to: { name: 'Optimizers' },
          wizards: [wizardTypes.assistant],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.roofConstruction'),
          icon: 'mdi-home-roof',
          to: { name: 'RoofConstruction' },
          wizards: [wizardTypes.assistant, wizardTypes.constructionCalculator],
          children: [
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.roofType'),
              icon: 'mdi-home-city-outline',
              to: { name: 'RoofType' },
            },
            {
              title: this.isPitched
                ? this.$i18n.t('components.wizard.wizardIndex.wizardSteps.pourType')
                : this.$i18n.t('components.wizard.wizardIndex.wizardSteps.installationType'),
              icon: 'mdi-home-search-outline',
              to: { name: 'PourType' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.screwType'),
              icon: 'mdi-screw-lag',
              to: { name: 'ScrewType' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.handleType'),
              icon: 'mdi-screwdriver',
              to: { name: 'HandleType' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.tcsType'),
              icon: 'mdi-screw-round-top',
              to: { name: 'TcsType' },
            },
            {
              title: this.isPitched
                ? this.$i18n.t('components.wizard.wizardIndex.wizardSteps.rafterSpacing')
                : this.$i18n.t('components.wizard.wizardIndex.wizardSteps.inclinationAngle'),
              icon: 'mdi-tape-measure',
              to: { name: this.isPitched ? 'RafterSpacing' : 'InclinationAngle' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.panelOrientation'),
              icon: 'mdi-solar-panel',
              to: { name: 'PanelOrientation' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.rowsNumber'),
              icon: 'mdi-solar-panel-large',
              to: { name: 'RowsNumber' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.moduleNumber'),
              icon: 'mdi-view-dashboard',
              to: { name: 'ModuleNumber' },
            },
            {
              title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.clampType'),
              icon: 'mdi-hammer-screwdriver',
              to: { name: 'ClampType' },
            },
          ],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.mountingAccessories'),
          icon: 'mdi-toolbox',
          to: { name: 'MountingAccessories' },
          wizards: [wizardTypes.assistant, wizardTypes.constructionCalculator],
        },
        {
          title: this.$i18n.t(
            'components.wizard.wizardIndex.wizardSteps.electricalInstallationEquipment'
          ),
          icon: 'mdi-toy-brick',
          to: { name: 'ElectricalInstallationEquipment' },
          wizards: [wizardTypes.assistant],
        },
        {
          title: this.$i18n.t('components.wizard.wizardIndex.wizardSteps.summary'),
          icon: 'mdi-view-list',
          to: { name: 'WizardSummary' },
          wizards: [wizardTypes.assistant, wizardTypes.constructionCalculator],
        },
      ];
      return steps.filter(this.filterWizardSteps);
    },
    ...pourTypeComputed,
    ...handleTypeComputed,
    ...incompatibleStepsComputed,
    ...incompatibleSubStepsComputed,
    ...declaredPowerChangedComputed,
    ...modeComputed,
    ...organizationComputed,
    ...cartComputed,
    ...selectedCurrencyObjComputed,
    ...wizardTypeComputed,
    ...installationMethodComputed,
    ...confirmResetModal,
    ...roofTypeComputed,
    isMobile() {
      return this.$vuetify.breakpoint.sm;
    },
    currentStep() {
      return this.$store.state.wizardStep;
    },
    currentSubStep() {
      return this.$store.state.wizardSubStep;
    },
    wizardMode() {
      return this.$store.state.mode;
    },
    isWizardSummary() {
      return this.$store.state.wizardStep === this.wizardSummaryStep;
    },
    btnText() {
      return this.isWizardSummary
        ? this.$i18n.t('components.wizard.wizardIndex.btnTextFunc.optionOne')
        : this.$i18n.t('components.wizard.wizardIndex.btnTextFunc.optionTwo');
    },
    isCreatingOfferForExternalOrg() {
      return !!this.organization.name;
    },
    isPitched() {
      return this.roofType === ROOF_TYPE.PitchedRoof;
    },
  },
  methods: {
    async resetWizard() {
      await this.$store.dispatch('resetWizard');
      await this.$store.commit('finishCreatingOfferForExternalClient');

      await this.$store.dispatch('setWizardType', this.confirmResetModal.type);
      await this.$store.dispatch('closeWizardTypeWithConfirmation');
    },
    async keepWizard() {
      await this.$store.dispatch('closeWizardTypeWithConfirmation');
    },
    filterWizardSteps(step) {
      return step.wizards.some((type) => type.name === this.wizardType.name);
    },
    clearShowMessage() {
      this.showModal = false;
      this.showBefore = false;
      this.showBeforeMessage = '';
      this.$toasted.clear();
    },
    confirmForward() {
      this.clearShowMessage();
      this.updateIncompatibleSteps();
      this.goForward();
    },
    isStepIncompatible(step) {
      const stepNumber = this.wizardSteps.indexOf(step);
      return this.incompatibleSteps.includes(stepNumber) && this.currentStep !== stepNumber;
    },
    isSubStepIncompatible(step, child) {
      const subStepNumber = step.children.indexOf(child);
      return (
        this.incompatibleSubSteps.includes(subStepNumber) && this.currentSubStep !== subStepNumber
      );
    },
    updateIncompatibleSteps() {
      if (this.currentStep !== 4) {
        this.$store.commit('addToIncompatibleSteps', this.currentStep);
      } else {
        this.$store.commit('addToIncompatibleSubSteps', this.currentSubStep);
      }
    },
    disagree() {
      this.showModal = false;
    },
    async continueInStarmont() {
      const clientId = this.organization.clientId;
      await this.$store.commit('resetWizardOptions');
      await this.$store.commit('finishCreatingOfferForExternalClient');
      window.location.href = `${config.STARMONT_CLIENTS_URL}/${clientId}`;
    },
    async continueInSolitan() {
      this.offerCreatedModal = false;
      this.$router.push({ name: 'Offers' });
      await this.$store.commit('resetWizardOptions');
      await this.$store.commit('finishCreatingOfferForExternalClient');
    },
    async finishAndCreateOffer() {
      try {
        if (!this.$refs.child.$refs.offerServices.servicesFormValid)
          return this.$toasted.global.error({
            message: this.$i18n.t(
              'components.wizard.wizardIndex.toasted.finishAndCreateOfferFunc.messageOne'
            ),
          });
        const offer = {
          productsRefs: this.cart.map((item) => ({
            product: item._id,
            quantity: item.quantity,
          })),
          deliveryPrice: 0,
          services: this.$refs.child.services,
          outsideOrg: {
            createdWithExternalOrg: true,
            clientId: this.organization.clientId,
            orgName: this.organization.name,
          },
          currency: this.selectedCurrencyObj,
        };
        await OfferService.create(offer);
        this.offerCreatedModal = true;
      } catch (err) {
        this.$toasted.global.error({
          message: this.$i18n.t(
            'components.wizard.wizardIndex.toasted.finishAndCreateOfferFunc.messageTwo'
          ),
        });
      }
    },
    areButtonsVisible(route = this.$route) {
      if (route.name === 'StartWizard' || route.name === 'WizardItemDetails') {
        this.showButtons = false;
      } else {
        this.showButtons = true;
      }
    },
    setIsForwardEnabled(active) {
      if (this.incompatibleSubSteps.includes(this.currentSubStep)) {
        this.$store.commit('removeFromIncompatibleSubSteps', this.currentSubStep);
      }
      this.isNextActive = active;
    },
    showBeforeFoward(show, options) {
      this.showBefore = show;
      this.showBeforeMessage = options.message;
    },
    goToCurrentStep() {
      const step = this.currentStep;

      const currentStepObject = this.wizardSteps[step];

      if (currentStepObject.children) {
        this.goToSubStep(currentStepObject);
      } else {
        this.goToStep();
      }
    },
    goBack() {
      const currentStepObject = this.wizardSteps[this.currentStep];
      if (this.currentStep === 0) {
        this.$router.push({ name: 'StartWizard' });
      }
      if (this.currentStep > 0) {
        if (currentStepObject?.children) {
          if (this.currentSubStep === 0) {
            this.$store.commit('decrementStep');
            this.goToStep();
          } else {
            this.$store.commit('decrementSubStep');
            this.goToSubStep(currentStepObject);
          }
        } else {
          this.$store.commit('decrementStep');
          if (this.wizardSteps[this.currentStep].children) {
            this.$store.commit(
              'setWizardSubStep',
              this.wizardSteps[this.currentStep].children.length - 1
            );
            this.goToSubStep(this.wizardSteps[this.currentStep]);
          } else {
            this.goToStep();
          }
        }
      }
      this.clearShowMessage();
      this.isNextActive = true;
    },
    validateSteps() {
      const stepsToValidate = ['DeclaredPower', 'RowNumber', 'ModuleNumbers', 'ModulesQuantity'];
      const type = this.$refs.child.$children[0].type || this.$refs.child.type;
      if (type && stepsToValidate.includes(type.name)) {
        type.isRoofConstruction
          ? this.$refs.child.$children[0].validate()
          : this.$refs.child.validate();
      }
    },
    async goForward() {
      this.validateSteps();
      if (this.isNextActive) {
        if (this.showBefore) {
          this.showModal = true;
        } else {
          const currentStepObject = this.wizardSteps[this.currentStep];
          if (this.currentStep === 0 && this.declaredPowerChanged) {
            await this.$store.commit('resetWizardAfterDeclaredPowerChanged');
          }
          if (this.currentStep < this.wizardSteps.length - 1) {
            if (currentStepObject?.children) {
              if (this.currentSubStep === this.wizardSteps[this.currentStep].children.length - 1) {
                this.$store.commit('incrementStep');
                this.goToStep();
              } else {
                this.$store.commit('incrementSubStep');
                this.goToSubStep(currentStepObject);
              }
            } else {
              this.$store.commit('incrementStep');
              this.goToStep();
            }
            this.isNextActive = true;
          } else {
            await this.$store.commit('resetWizardOptions');
            this.$router.push({ name: 'TransactionSummary' });
          }

          this.clearShowMessage();
        }
      } else {
        this.$toasted.global.error({
          message: this.$i18n.t('components.wizard.wizardIndex.toasted.goForwardFunc'),
        });
      }
    },
    goToStep() {
      this.$router.push(this.wizardSteps[this.currentStep].to);
    },
    goToSubStep(currentStep) {
      this.$router.push(currentStep.children[this.currentSubStep].to);
    },
    isDisabled(step) {
      const currStep = this.currentStep;

      const stepNumber = this.wizardSteps.indexOf(step);

      if (stepNumber <= currStep) {
        return false;
      } else {
        return true;
      }
    },
    isActive(step) {
      const stepNumber = this.wizardSteps.indexOf(step);
      if (stepNumber === this.currentStep) {
        return true;
      } else return false;
    },
    isCrossedSubstep(step, substep) {
      const stepNumber = step.children.indexOf(substep);

      if (noHandleTypePourTypes.includes(this.pourType) && stepNumber === 3) {
        return true;
      }

      if (this.pourType !== POUR_TYPE.BLACHODACHOWKA && stepNumber === 2) {
        return true;
      }

      if (
        this.installationMethod === INSTALLATION_METHODS.NonIvasive &&
        stepNumber === 2 &&
        this.roofType === ROOF_TYPE.FlatRoof
      ) {
        return true;
      }

      if (
        !permamentPourTypes.includes(this.pourType) &&
        stepNumber === 4 &&
        ![POUR_TYPE.KARPIOWKA, POUR_TYPE.DACHOWKA_CERAMICZNA].includes(this.pourType)
      ) {
        return true;
      }
    },
    isDisabledSubstep(step, substep) {
      const currStep = this.currentStep;
      const stepNumber = this.wizardSteps.indexOf(step);
      if (stepNumber < currStep) {
        return false;
      } else if (stepNumber > currStep) {
        return true;
      } else {
        const currSubStep = this.currentSubStep;
        const stepNumber = step.children.indexOf(substep);

        if (noHandleTypePourTypes.includes(this.pourType) && stepNumber === 3) {
          return true;
        }

        if (this.pourType !== POUR_TYPE.BLACHODACHOWKA && stepNumber === 2) {
          return true;
        }

        if (
          this.installationMethod === INSTALLATION_METHODS.NonIvasive &&
          stepNumber === 2 &&
          this.roofType === ROOF_TYPE.FlatRoof
        ) {
          return true;
        }

        if (
          !permamentPourTypes.includes(this.pourType) &&
          stepNumber === 4 &&
          ![POUR_TYPE.KARPIOWKA, POUR_TYPE.DACHOWKA_CERAMICZNA].includes(this.pourType)
        ) {
          return true;
        }

        if (stepNumber <= currSubStep) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  mounted() {
    if (this.wizardMode === '') {
      this.$router.replace({ name: 'StartWizard' });
    }
    this.areButtonsVisible();

    const _this = this;
    const wizardComponentsWithDefaultBackAction = ['StartWizard', 'WizardItemDetails'];
    window.onpopstate = function () {
      const { name, fullPath } = _this.$router.currentRoute;
      if (!wizardComponentsWithDefaultBackAction.includes(name) && fullPath.includes('doradca')) {
        _this.goBack();
      }
    };
  },
  async destroyed() {
    await this.$store.commit('finishCreatingOfferForExternalClient');
  },
};
</script>
<style lang="scss" scoped>
.crossed {
  text-decoration: line-through !important;
}
</style>
