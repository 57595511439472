<template>
  <title-wrapper :title="$t('components.wizard.inclinationAngle.title')">
    <selectable-section
      :label="$t('components.wizard.inclinationAngle.selectLabel')"
      :items="inclinationAngles"
      storeAction="setInclinationAngle"
      :active="inclinationAngle"
    ></selectable-section>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../components/TitleWrapper.vue';
import SelectableSection from '../../../components/SelectableSection.vue';
import { inclinationAngleComputed } from '../../../store/helper';

export default {
  name: 'RafterSpacing',
  components: { TitleWrapper, SelectableSection },
  data() {
    return {
      inclinationAngles: [
        {
          value: 15,
          text: '15°',
        },
        {
          value: 35,
          text: '35°',
        },
      ],
    };
  },
  computed: {
    ...inclinationAngleComputed,
  },
  activated() {
    this.$emit('setIsForwardEnabled', true);
  },
};
</script>
